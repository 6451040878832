:root {
  --primary: #3c6444;
  --secondary: #ccc;
  --auxiliar: #b2b2b2;
  --layout: #f5f5f5;
  --border-radius: "15px";
}

.mb-24 {
  margin-bottom: 24px;
}

.align-center {
  text-align: center;
}

.pt-2 {
  padding-top: 2rem;
}

.padding-all {
  padding: 1rem;
}

.align-center {
  align-self: center;
}

.dashboard {
  padding: 1.5rem;
  min-height: 80vh;
}

.subtitle_dashboard {
  color: var(--primary);
  font-weight: bold;
}

.title {
  font-size: 1.2rem;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  color: var(--layout);
}

.card {
  box-shadow: "0px 40px 60px #0000000d";
  border-radius: 18px;
  height: 100%;
}

.card-title {
  box-shadow: "0px 40px 60px #0000000d";
  border-radius: 18px;
  background-color: var(--secondary);
  height: 100%;
}
.card-title:hover {
  cursor: pointer;
}

.primary_button {
  width: 8rem;
  margin: 10px;
}

.icon {
  width: 32px;
  height: 32px;
  background-color: var(--primary);
  border-radius: 8px;
  border: solid 1px var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon svg {
  color: var(--layout);
}

.overflow_x {
  overflow-x: scroll;
}

.alert {
  position: fixed;
  top: 20px;
  right: 10px;
}

.card_grid {
  color: var(--primary);
}
.card_grid_disabled {
  color: var(--secondary);
}

.cell {
  width: 10%;
  min-width: 120px;
}
